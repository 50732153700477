<template>
  <div class="column-flex-container">
    <template v-if="state.appState === APP_STATES.LOADING">
      <slot name="loading">
        <!--    TODO: Add Some Abstract Loading Screen -->
        <SpinningLogo />
      </slot>
    </template>
    <template v-else-if="state.appState === APP_STATES.ERROR">
      <slot name="error">
        <div class="loading-container">
          <p>{{ state.error }}</p>
        </div>
      </slot>
    </template>
    <div v-show="state.appState === APP_STATES.READY" class="column-flex-container">
      <slot name="above"></slot>
      <div :id="rootElementId" class="app-container"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRefs } from 'vue';
import { useAppsRenderer } from '@/composable/apps-renderer';
import SpinningLogo from '@/components/SpinningLogo.vue';

export default defineComponent({
  name: 'AppsRenderer',
  components: { SpinningLogo },
  setup(props, vueContext) {
    const { renderedApp, context } = toRefs(props);
    return useAppsRenderer(renderedApp, context, vueContext);
  },

  props: {
    renderedApp: {
      type: Object,
      default: () => ({})
    },

    uiSettings: {
      type: Object,
      default: () => ({})
    },

    context: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['modal-toggle']
});
</script>

<style scoped>
.column-flex-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.loading-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-container {
  display: flex;
  flex: 1;
  min-height: 0; /* Required for the scrollbar in non-Chrome browsers */
  min-width: 0; /* Required for flexbox responsiveness with long text */
}
</style>
