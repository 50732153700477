import { v4 as uuid } from 'uuid';
import { reactive, toRaw, toRefs } from 'vue';
import { PLATFORM } from '@/common/const';

const CONTEXT_CACHE_KEY = 'applicationContext';

export type VBCShellContext = {
  deviceId: string;
  platform: PLATFORM;
  hostClientType: string;
  appId: string;
  routeId?: string;
  routeContext?: Record<string, string>;
};

let initialContext = {
  appId: '',
  platform: PLATFORM.WEB,
  hostClientType: 'web',
  deviceId: uuid()
};

const fromCache = localStorage.getItem(CONTEXT_CACHE_KEY);
if (fromCache) {
  initialContext = JSON.parse(fromCache);
}

const state = reactive<{ context: VBCShellContext }>({
  context: initialContext
});

export function removeCache(): Promise<void> {
  return new Promise(resolve => {
    localStorage.removeItem(CONTEXT_CACHE_KEY);
    resolve();
  });
}

function setVBCShellContext(newContext: Partial<VBCShellContext>): void {
  state.context = { ...state.context, ...newContext };
  localStorage.setItem(CONTEXT_CACHE_KEY, JSON.stringify(toRaw(state.context)));
}

export function useContext() {
  return {
    ...toRefs(state),
    setVBCShellContext
  };
}
