/* This file is needed because we need to support messaging app
with the VBC's functionalities, after we will add the contact service
to VBC we will be able to use only the contact service without making
changes to objects before sending them back to the app
 */
import { reactive, toRef, toRaw, computed } from 'vue';
import get from 'lodash.get';
import set from 'lodash.set';
import isEmpty from 'lodash.isempty';
import { v1 as uuidv1 } from 'uuid';
import { calculateTimeDifferenceInHours } from '@/common/utils';
import ContactsService from '@vonage/contacts-service';
import { useExtensions } from '@/modules/user';
import { useMyApps } from '@/modules/my-apps';
import { sortAlphabeticalByDisplayName } from '@/common/utils';
import { ACTIONS, EVENTS } from '@/modules/actions';

export const CONTACTS_INTEGRATION_PLUGIN = 'contacts-integration';
const VBC_CONTACT_PROVIDER_ID = 'VBC';
const CONTACTS_TIME_SYNC = parseInt(process.env.VUE_APP_VBC_CONTACTS_TIME_SYNC) || 60000 * 60;
const QUERY_RESULT = {
  CURSORS: 'cursors',
  CONTACTS: 'contacts'
};
const CURSORS = {
  NEXT: 'next',
  PROVIDER_CURSOR: 'providerCursor'
};

const UPDATE_RESULT = {
  CURSORS: 'cursors',
  UPDATES: 'updates'
};

const UPDATES = {
  CONTACTS: 'contacts',
  DELETIONS: 'deletions'
};

const SYNC_CONTACTS_PAGE_SIZE = 100;

import BaseIndexedDB from '@/db/BaseIndexedDB';
import { useAuth } from '@/modules/auth';
import { useActions } from '@/modules/actions';

let db;

const DB_INFO = {
  name: 'Contacts',
  objectStoreName: 'contacts',
  keys: ['contacts', 'cursor'],
  schema: { contacts: '' },
  version: 1
};

const CACHE_TIMESTAMP_KEY = 'contacts.cacheTimestamp';
const CACHE_TTL_HOURS = 12;

const state = reactive({
  contacts: [] as any,
  cursor: ''
});

let syncVBCContactsInterval;

export async function removeCache() {
  if (!db) {
    console.log('Contacts - No DB FOUND will not delete db');
    return;
  }
  localStorage.removeItem(CACHE_TIMESTAMP_KEY);
  await db.delete();

  clearInterval(syncVBCContactsInterval);
}
const { accessToken } = useAuth();
const { activeExtension } = useExtensions();
const { getAppsByPlugin } = useMyApps();
const { registerActionExecutor, executeAction } = useActions();

const contactsProvidersConfig = computed(() => {
  const contactIntegrationApps = getAppsByPlugin(CONTACTS_INTEGRATION_PLUGIN);
  return contactIntegrationApps.map(app => ({
    id: app.id || app.appId,
    ...app.plugins[CONTACTS_INTEGRATION_PLUGIN]
  }));
});

async function searchContactByNumber(numbers: string[]) {
  return await ContactsService.searchNumbers(numbers);
}

async function syncContacts(date: string | undefined, cursor: any, forward: boolean) {
  let contacts = {} as any;
  let deletions = [] as string[];
  let contactsResponse = {} as any;
  let loopCondition = false;
  do {
    try {
      // if we have a cursor sync by the cursor, if not sync by the date
      if (cursor) {
        contactsResponse = await ContactsService.getContactsUpdates(VBC_CONTACT_PROVIDER_ID, cursor, undefined, forward, SYNC_CONTACTS_PAGE_SIZE);
      } else {
        contactsResponse = await ContactsService.getContactsUpdates(VBC_CONTACT_PROVIDER_ID, undefined, date, forward, SYNC_CONTACTS_PAGE_SIZE);
      }
      contacts = { ...contacts, ...get(contactsResponse, [UPDATE_RESULT.UPDATES, UPDATES.CONTACTS], {}) };
      deletions = deletions.concat(get(contactsResponse, [UPDATE_RESULT.UPDATES, UPDATES.DELETIONS], []));
      loopCondition = forward
        ? !isEmpty(Object.keys(get(contactsResponse, [UPDATE_RESULT.UPDATES, UPDATES.CONTACTS])))
        : get(contactsResponse, [UPDATE_RESULT.CURSORS, CURSORS.NEXT, CURSORS.PROVIDER_CURSOR]);
      cursor = get(contactsResponse, [UPDATE_RESULT.CURSORS, CURSORS.NEXT]);
      date = undefined;
    } catch (e) {
      console.error(`Error syncing contacts: ${e}`);
      return;
    }
  } while (loopCondition);
  if (forward) {
    state.cursor = get(contactsResponse, [UPDATE_RESULT.CURSORS, CURSORS.NEXT]);
    await db.saveStore({ cursor: toRaw(state.cursor) }, DB_INFO.objectStoreName);
  }
  return { contacts, deletions };
}

async function updateContacts() {
  const contactsMap = {};
  let stateContacts = state.contacts as any;

  const syncResult = await syncContacts(undefined, toRaw(state.cursor), true);

  const updatedOrNew = get(syncResult, 'contacts', {});
  const deletions = get(syncResult, 'deletions', []);

  if (isEmpty(Object.values(updatedOrNew)) && isEmpty(deletions)) {
    return;
  }

  stateContacts.forEach((contact: any, index) => (contactsMap[contact.id] = index));

  const newContacts = [] as any;
  // If contact is already in state, update it. Else add it to the state.
  Object.values(updatedOrNew).forEach((contact: any) => {
    const contactIndex = contactsMap[contact.id];
    if (contactIndex >= 0) {
      // Replace contact with the updated contact.
      set(state.contacts, contactIndex, Object.seal(contact));
    } else {
      newContacts.push(contact);
    }
  });

  if (newContacts.length > 0) {
    stateContacts.push(...newContacts);
  }

  deletions.forEach(id => {
    const index = stateContacts.findIndex((contact: any) => contact.id === id);
    if (index >= 0) {
      stateContacts.splice(index, 1);
    }
  });

  // Sort contacts for display. No need to sort if no contacts were updated or added.
  if (!isEmpty(Object.keys(updatedOrNew))) {
    stateContacts = stateContacts.sort(sortAlphabeticalByDisplayName);
  }

  await db.saveStore({ contacts: toRaw(stateContacts) }, DB_INFO.objectStoreName);
  localStorage.setItem(CACHE_TIMESTAMP_KEY, Date.now().toString());

  const actionId = uuidv1();
  await executeAction({ action: { type: ACTIONS.SEND_EVENTS_TO_HOSTED_APP, id: actionId, parameters: { eventName: EVENTS.CONTACTS_UPDATE } }, context: {} });
}

async function getAllContacts() {
  return toRaw(state.contacts);
}

async function getAllVBCContacts() {
  const rawContacts = toRaw(state.contacts);
  if (!isEmpty(rawContacts)) {
    ContactsService.setSearchableProvider(VBC_CONTACT_PROVIDER_ID, false);
    return;
  }
  let contacts = {} as any;
  const date = Date.now().toString();
  try {
    const [newContacts, oldContacts] = (await Promise.all([syncContacts(date, '', true), syncContacts(date, '', false)])) as any;
    contacts = { ...newContacts.contacts, ...oldContacts.contacts };
  } catch (e) {
    console.error(`Error getting all contacts: ${e}`);
  }
  ContactsService.setSearchableProvider(VBC_CONTACT_PROVIDER_ID, false);
  contacts = Object.values(contacts).sort(sortAlphabeticalByDisplayName);
  state.contacts = contacts;
  localStorage.setItem(CACHE_TIMESTAMP_KEY, Date.now().toString());
  await db.saveStore({ contacts: contacts }, DB_INFO.objectStoreName);
}

async function getMoreContacts(cursor, filter, pageSize) {
  let contactsResponse = {} as any;
  let contacts = {};
  do {
    try {
      contactsResponse = await ContactsService.search(cursor, filter, pageSize);
      contacts = { ...contacts, ...contactsResponse.contacts };
      cursor = get(contactsResponse, [QUERY_RESULT.CURSORS, CURSORS.NEXT]);
    } catch (e) {
      console.error(`Error getting more contacts: ${e}`);
      return {};
    }
  } while (Object.keys(get(contactsResponse, 'contacts', [])).length < pageSize && !isEmpty(get(contactsResponse, [QUERY_RESULT.CURSORS, CURSORS.NEXT])));
  set(contactsResponse, 'contacts', Object.values(contacts));
  return contactsResponse;
}

export const CONTACT_SERVICE_FUNCTIONS_MAP = {
  'update-config': (payload: any) =>
    ContactsService.updateConfig(
      payload.config,
      () => accessToken.value,
      () => get(activeExtension, ['value', 'id']),
      get(payload, ['vbcProvider'], true)
    ),
  'search-by-provider': async payload => await ContactsService.searchByProvider(payload.providerId, payload.searchQuery),
  'search-numbers': async payload => await ContactsService.searchNumbers(payload.numbers),
  'add-favorite': async payload => {
    await ContactsService.addFavorite(payload.providerId, payload.contactId);
  }, // TODO: remove add favorite from sdk
  'remove-favorite': async payload => await ContactsService.removeFavorite(payload.providerId, payload.contactId),
  'update-contacts': async payload => await ContactsService.updateContacts(payload.providerId, payload.contactId),
  'add-contacts': async payload => await ContactsService.addContacts(payload.providerId, payload.contacts),
  'remove-contacts': async payload => await ContactsService.removeContacts(payload.providerId, payload.contacts),
  'get-all-providers': () => ContactsService.allProviders(),
  'get-contacts': async () => {
    const contacts = await getAllContacts();
    return Object.values(contacts);
  },
  'search-contact-by-number': async ({ action }) => {
    const contacts = await ContactsService.searchNumbers(get(action, ['parameters', 'numbers']));
    return Object.values(contacts);
  },
  'get-external-contacts': async ({ action }) => {
    return await getMoreContacts(
      get(action, ['parameters', 'cursor'], {}),
      get(action, ['parameters', 'filter'], ''),
      get(action, ['parameters', 'pageSize'], 20)
    );
  },
  'get-external-contact-providers': () => {
    return ContactsService.allProvidersArray;
  },
  'toggle-favorite': async payload => {
    const cuid = payload?.action?.parameters?.contact?.cuid;
    const isFavorite = payload?.action?.parameters?.contact?.isFavorite;
    const providerId = payload?.action?.parameters?.contact?.providerId;
    if (isFavorite) {
      await ContactsService.removeFavorite(providerId, cuid);
    } else {
      await ContactsService.addFavorite(providerId, cuid);
    }
    await updateContacts();
  }
};

async function initContactsService() {
  const { accessToken } = useAuth();

  const { activeExtension } = useExtensions();
  if (!activeExtension.value) {
    throw new Error('No Active Extension');
  }
  try {
    await ContactsService.init(
      contactsProvidersConfig.value,
      () => accessToken.value,
      () => get(activeExtension, ['value', 'id'])
    );
  } catch (e) {
    console.error('Could not initialize Contacts Service');
  }
  if (!db) {
    db = new BaseIndexedDB(DB_INFO.name, DB_INFO.version, DB_INFO.schema);
  }

  const cacheTimestamp = JSON.parse(localStorage.getItem(CACHE_TIMESTAMP_KEY) || '0');
  const cacheTimestampDiffInHours = calculateTimeDifferenceInHours(cacheTimestamp);
  if (cacheTimestampDiffInHours <= CACHE_TTL_HOURS) {
    const dbValues = await db.getAllData(DB_INFO.keys, DB_INFO.objectStoreName);
    state.cursor = get(dbValues, 'cursor', '');
    if (state.cursor) {
      state.contacts = get(dbValues, 'contacts', []);
    }
  }
  for (const [actionType, actionExecutor] of Object.entries(CONTACT_SERVICE_FUNCTIONS_MAP)) {
    try {
      registerActionExecutor(actionType, actionExecutor);
    } catch (e) {
      console.error(`Failed registering action of type: ${actionType} - ${e}`);
    }
  }
  await getAllVBCContacts();

  if (!syncVBCContactsInterval) {
    syncVBCContactsInterval = setInterval(() => {
      updateContacts();
    }, CONTACTS_TIME_SYNC);
  }
}

export function useContacts() {
  return {
    contacts: toRef(state, 'contacts'),
    initContactsService,
    getMoreContacts,
    searchContactByNumber
  };
}
