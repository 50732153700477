
import { defineComponent, reactive } from 'vue';
import { useHost } from '@/modules/host';
import { get } from 'lodash';
import Avatar from '@/components/Avatar.vue';
import { useExtensions, useUser } from '@/modules/user';
import { sendEventToHostedApp } from '@/composable/apps-renderer';

const TITLE_BAR_BUTTON_CLICKED_EVENT = 'title-bar-button-clicked';
const LOGOUT_EVENT = 'logout';

export default defineComponent({
  name: 'TitleBar',
  props: {
    appId: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    data: {
      type: Object,
      default: () => ({})
    }
  },

  components: {
    Avatar
  },

  methods: {
    isMobile() {
      const appleRegex = /iPhone|iPad|iPod/i;
      const androidRegex = /Android|webOS|Mobile|Opera Mini|IEMobile|Blackberry/i;
      return navigator.userAgent.match(appleRegex) || navigator.userAgent.match(androidRegex);
    },
    isHidden() {
      return this.isMobile() && this.appId === 'fax';
    }
  },
  setup(props) {
    const state = reactive({
      isAvatarMenuOpen: false
    });

    const { logout } = useHost();
    const { user } = useUser();
    const { activeExtension } = useExtensions();

    const buttons = get(props.data, 'buttons', []);
    const appVersion = process.env.VUE_APP_VERSION;
    const gitRevision = process.env.VUE_APP_GIT_REVISION;

    const onLogoutClick = async () => {
      await logout();
      try {
        await sendEventToHostedApp(props.appId, LOGOUT_EVENT);
      } catch (e) {
        console.error(`Error sending event - ${e}`);
      }
    };
    const onClick = async button => {
      try {
        await sendEventToHostedApp(props.appId, TITLE_BAR_BUTTON_CLICKED_EVENT, { event: button.event });
      } catch (e) {
        console.error(`Error sending event - ${e}`);
      }
    };
    return {
      state,
      onLogoutClick,
      onClick,
      buttons,
      user,
      activeExtension,
      appVersion,
      gitRevision
    };
  }
});
