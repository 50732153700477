import { useContext } from './context';
import { useAuth } from '@/modules/auth';
import { PLATFORM } from '@/common/const';

const { context } = useContext();
const { logout } = useAuth();

interface Host {
  init: () => void;
  logout: () => void;
}

let host: Host;

export async function resolveHost(): Promise<Host> {
  if (host) {
    return host;
  }

  switch (context.value.platform) {
    case PLATFORM.MS_TEAMS:
      // eslint-disable-next-line no-case-declarations
      const { default: useMSTeams } = await import(/* webpackChunkName: "ms-teams-auth-adapter" */ '../modules/ms-teams');
      // eslint-disable-next-line no-case-declarations
      const { logoutUserWithPopup, startTeamsVBCSession } = useMSTeams();
      host = {
        init: startTeamsVBCSession,
        logout: logoutUserWithPopup
      };
      break;
    default:
      host = {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        init: () => {},
        logout
      };
  }
  return host;
}

export function useHost(): Host {
  return {
    init: async () => {
      if (!host) {
        throw new Error('Host was not initialized');
      }
      await host.init();
    },
    logout: async () => {
      if (!host) {
        throw new Error('Host was not initialized');
      }
      await host.logout();
    }
  };
}
