import { v4 as uuid } from 'uuid';
import { decode } from 'jsonwebtoken';
import { getQueryParameters, toQueryString } from '@/common/utils';
import { getSSOTokens, refreshAccessTokenAPI } from '@/api/auth';
import { initVBCAPIGateway } from '@/api';
import { LOCAL_STORAGE } from '@/common/const';

const { SSO_STATE_KEY, VBC_TOKENS_CACHE_KEY } = LOCAL_STORAGE;

export type VBCTokens = { apiGatewayAccessToken: string; apiGatewayRefreshToken: string; idpAccessToken: string; idpLogoutToken: string };

export default class VBCAuthService {
  protected authProviderURL: string;
  protected clientId: string;
  protected VBCTokens: VBCTokens | null;
  protected authCallbackURL: string;
  protected postLogoutURL: string;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private readonly tokensUpdatedCallback: (VBCTokens) => void = () => {};
  private refreshAccessTokenPromise: Promise<void> | null;

  constructor(options) {
    this.clientId = options.clientId;
    this.authProviderURL = options.authProviderURL;
    this.authCallbackURL = options.authCallbackURL;
    this.postLogoutURL = options.postLogoutURL;
    this.VBCTokens = null;
    if (options.onTokensUpdated) {
      this.tokensUpdatedCallback = options.onTokensUpdated;
    }
    this.restoreTokens();
    initVBCAPIGateway(this);
    this.refreshAccessTokenPromise = null; // TODO add mechanism to refresh the access token 1 hour before it expires automatically.
  }

  public logout(options: { postLogoutURL?: string }) {
    localStorage.removeItem(VBC_TOKENS_CACHE_KEY);

    if (this.VBCTokens) {
      const queryParams = {
        id_token_hint: this.VBCTokens.idpLogoutToken, // eslint-disable-line @typescript-eslint/camelcase
        post_logout_redirect_uri: options.postLogoutURL || this.postLogoutURL // eslint-disable-line @typescript-eslint/camelcase
      };

      const logoutEndpoint = `${this.authProviderURL}/oidc/logout?${toQueryString(queryParams)}`;
      window.location.replace(logoutEndpoint);
    } else {
      window.location.replace(window.location.origin);
    }
  }

  public redirectToSSO(options: { authCallbackURL: string; customState?: object } = { authCallbackURL: '', customState: undefined }) {
    // Generate random state string and store it, so we can verify it in the callback
    const id = uuid();
    const state = JSON.stringify({ id, customState: options.customState });
    localStorage.setItem(SSO_STATE_KEY, id);
    // Go to the VBC SSO auth endpoint
    const queryParams = {
      client_id: this.clientId, // eslint-disable-line @typescript-eslint/camelcase
      response_type: 'code', // eslint-disable-line @typescript-eslint/camelcase
      scope: 'openid',
      redirect_uri: options.authCallbackURL || this.authCallbackURL, // eslint-disable-line @typescript-eslint/camelcase
      state
    };

    const authorizeEndpoint = `${this.authProviderURL}/oauth2/authorize?${toQueryString(queryParams)}`;
    console.log(authorizeEndpoint);
    window.location.replace(authorizeEndpoint);
  }

  public async consumeSSOTokens(options = { authCallbackURL: '' }): Promise<{ customState: object | undefined; VBCTokens: VBCTokens }> {
    const { code, customState } = this.parseSSOQueryParams();

    const VBCTokens = await getSSOTokens(code, options.authCallbackURL || this.authCallbackURL);

    this.setTokens(VBCTokens);

    return { customState, VBCTokens };
  }

  public async refreshAccessToken(): Promise<VBCTokens | null> {
    if (this.refreshAccessTokenPromise) {
      await this.refreshAccessTokenPromise;
      return this.VBCTokens;
    }

    this.refreshAccessTokenPromise = this.doRefreshAccessToken();
    await this.refreshAccessTokenPromise;
    this.refreshAccessTokenPromise = null;

    return this.VBCTokens;
  }

  public setTokens(tokens: VBCTokens, options = { useCache: true }): void {
    console.log(`Setting tokens into VBCAuthService ${JSON.stringify(tokens)}`);
    this.VBCTokens = tokens;
    if (options.useCache) {
      localStorage.setItem(VBC_TOKENS_CACHE_KEY, JSON.stringify(tokens));
    }
    this.tokensUpdatedCallback(this.VBCTokens);
  }

  public get tokens() {
    return this.VBCTokens;
  }

  public async getAccessToken() {
    const minimumTimeToExpire = 60 * 60 * 1000; // if the token is valid for less than an hour, create a new one

    if (!this.VBCTokens?.apiGatewayAccessToken) {
      throw new Error('Unable to fetch token');
    }

    const accessTokenDecoded = decode(this.VBCTokens.apiGatewayAccessToken);
    const expireDate = new Date(accessTokenDecoded.exp * 1000);
    const timeToExpire = expireDate.getTime() - Date.now();

    if (timeToExpire < minimumTimeToExpire) {
      await this.refreshAccessToken();
    }

    return {
      accessToken: this.VBCTokens.apiGatewayAccessToken,
      refreshToken: this.VBCTokens.apiGatewayRefreshToken
    };
  }

  private async doRefreshAccessToken() {
    if (!this.VBCTokens) {
      throw new Error('User Not Authenticated');
    }

    const { access_token, refresh_token } = await refreshAccessTokenAPI(this.VBCTokens.apiGatewayRefreshToken); // eslint-disable-line @typescript-eslint/camelcase
    this.setTokens({ ...this.VBCTokens, apiGatewayAccessToken: access_token, apiGatewayRefreshToken: refresh_token }); // eslint-disable-line @typescript-eslint/camelcase
  }

  private parseSSOQueryParams() {
    const SSO_CODE_KEY = 'code';
    // Split the key-value pairs passed from VBC SSO
    const queryParams = getQueryParameters();

    if (queryParams['error']) {
      // Authentication/authorization failed
      throw new Error(queryParams['error']); // TODO ask for the correct query params for error redirected from SSO
    }

    const state = JSON.parse(queryParams.state);
    const id = state.id;
    const customState = state.customState;

    if (queryParams[SSO_CODE_KEY]) {
      // Get the stored state parameter and compare with incoming state
      const expectedState = localStorage.getItem(SSO_STATE_KEY);
      if (expectedState !== id) {
        // State does not match, report error
        throw new Error('StateDoesNotMatch');
      } else {
        // Success: return token information to the tab
        localStorage.removeItem(SSO_STATE_KEY);
        console.log(`SSO State match, returning code ${queryParams[SSO_CODE_KEY]}`);
        return {
          code: queryParams[SSO_CODE_KEY],
          customState
        };
      }
    } else {
      throw new Error('UnexpectedFailure');
    }
  }

  private restoreTokens() {
    const tokens = localStorage.getItem(VBC_TOKENS_CACHE_KEY);
    if (tokens) {
      this.setTokens(JSON.parse(tokens), { useCache: false });
    }
  }
}
