import axios from 'axios';
import axiosRetry from 'axios-retry';
import get from 'lodash.get';

export const VBC_LAMBDA_API = axios.create({
  baseURL: process.env.VUE_APP_VBC_LAMBDA_URL
});

axiosRetry(VBC_LAMBDA_API, { retries: 3 });

export const VBC_API_GATEWAY = axios.create({
  baseURL: process.env.VUE_APP_VBC_API_GATEWAY
});

axiosRetry(VBC_API_GATEWAY, { retries: 3 });

export function initVBCAPIGateway(authService) {
  VBC_API_GATEWAY.interceptors.request.use(requestConfig => {
    if (requestConfig.url && requestConfig.url.includes('/oauth/token?grant_type=refresh_token')) {
      requestConfig.headers['Authorization'] = 'Basic bW9iaWxlOg==';
      return requestConfig;
    }
    requestConfig.headers['Authorization'] = `bearer ${authService.tokens?.apiGatewayAccessToken}`;
    return requestConfig;
  });

  VBC_API_GATEWAY.interceptors.response.use(undefined, async error => {
    let response;
    const status = get(error, 'response.status');
    const description = get(error, 'response.data.error_description', '');
    if (status === 401 && description.includes('Access token expired')) {
      try {
        await authService.refreshAccessToken();
        response = await VBC_API_GATEWAY.request(error.config);
      } catch (e) {
        console.error(e);
        throw e;
      }
      return response;
    }
    throw error;
  });
}
