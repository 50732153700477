<template>
  <div class="loading-container">
    <img src="../assets/spinning-logo.gif" alt="Loading" />
  </div>
</template>

<script>
export default {
  name: 'SpinningLogo'
};
</script>

<style scoped>
.loading-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
