<template>
  <p v-if="state.error">
    {{ state.error }}
  </p>
  <SpinningLogo v-else />
</template>

<script>
import { reactive } from 'vue';

import { useAuth } from '@/modules/auth';
import { useContext } from '@/modules/context';

import SpinningLogo from '@/components/SpinningLogo';

import { getQueryParameters } from '@/common/utils';

export default {
  name: 'Login',
  components: { SpinningLogo },
  setup() {
    const state = reactive({
      error: ''
    });

    const { setVBCShellContext } = useContext();
    const { redirectToSSO } = useAuth();

    let routeContext;
    let qs;

    try {
      qs = getQueryParameters();

      if (!qs.appId) {
        throw new Error('Missing appId queryString parameter');
      }

      try {
        if (qs.routeContext) {
          routeContext = JSON.parse(qs.routeContext);
        }
      } catch (err) {
        console.error(err);
      }

      const context = {
        appId: qs.appId,
        hostClientType: qs.hostClientType || undefined,
        routeId: qs.routeId || undefined,
        routeContext
      };

      setVBCShellContext(context);
      redirectToSSO();
    } catch (e) {
      state.error = e.toString();
    }

    return { state };
  }
};
</script>
