import get from 'lodash.get';
import { reactive, toRefs, toRaw } from 'vue';
import { useExtensions } from '@/modules/user';
import { VBCAction } from '@/modules/actions';
import { getExtensionAppsConfig } from '@/api/app-center';
import BaseIndexedDB from '@/db/BaseIndexedDB';
import { calculateTimeDifferenceInHours } from '@/common/utils';

let db;

const DB_INFO = {
  name: 'AppCenter',
  tableName: 'appsConfig',
  schema: { appsConfig: 'appId' },
  version: 1
};

const CACHE_TIMESTAMP_KEY = 'myApps.cacheTimestamp';
const CACHE_TTL_HOURS = 12;

const state = reactive<{ appsConfig: { [key: string]: { actions?: VBCAction[]; permissions?: { events: string[] } } } }>({
  appsConfig: {}
});

export function getAppsByPlugin(pluginName) {
  const apps = [] as any;
  Object.values(state.appsConfig).forEach((app: any) => {
    if (get(app, ['plugins', pluginName])) {
      apps.push(app);
    }
  });
  return apps;
}

export async function removeCache() {
  if (!db) {
    console.log('MY APPS - No DB FOUND will not delete db');
    console.log(db);
    console.log(localStorage.getItem(CACHE_TIMESTAMP_KEY));
    return;
  }
  localStorage.removeItem(CACHE_TIMESTAMP_KEY);
  await db.delete();
}

async function loadAppsConfig() {
  const { activeExtension } = useExtensions();
  if (!activeExtension.value) {
    throw new Error('No Active Extension');
  }
  if (!db) {
    db = new BaseIndexedDB(DB_INFO.name, DB_INFO.version, DB_INFO.schema);
  }
  const cacheTimestamp = JSON.parse(localStorage.getItem(CACHE_TIMESTAMP_KEY) || '0');
  const cacheTimestampDiffInHours = calculateTimeDifferenceInHours(cacheTimestamp);
  if (cacheTimestampDiffInHours <= CACHE_TTL_HOURS) {
    const appsConfigArray = await db.getAllTableData(DB_INFO.tableName);
    state.appsConfig = appsConfigArray.reduce((allApps, app) => {
      allApps[app.appId] = app;
      return allApps;
    }, {});
    return;
  }

  state.appsConfig = await getExtensionAppsConfig(activeExtension.value.id);
  localStorage.setItem(CACHE_TIMESTAMP_KEY, new Date().getTime().toString());
  const rawAppsConfig = toRaw(state.appsConfig);
  // We are intentionally not waiting for the saveTable promise.
  db.saveTable(Object.values(rawAppsConfig), DB_INFO.tableName);
}

export function useMyApps() {
  return {
    ...toRefs(state),
    loadAppsConfig,
    getAppsByPlugin
  };
}
