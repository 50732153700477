import get from 'lodash.get';

declare global {
  interface Window {
    _LTracker: { push: (data: Record<string, string>) => void };
  }
}

export default {
  // contextData here is separated from the init in case there's an issue before the init
  // function runs
  contextData: {
    appName: 'VBC-Shell-Client',
    appEnv: process.env.VUE_APP_ENV
  },

  init(accountInfo): Promise<void> {
    return new Promise(resolve => {
      this.contextData = {
        ...this.contextData,
        ...accountInfo
      };
      resolve();
    });
  },

  push(log) {
    if (typeof log !== 'object') {
      throw new Error('Log must be an object');
    }
    if (get(window, ['_LTracker', 'push']) && process.env.VUE_APP_ENV === 'production') {
      window._LTracker.push({
        ...log,
        contextData: this.contextData
      });
    }
  }
};
