<template>
  <div>
    <vwc-icon type="brand-vonage" class="logo" />
    <h3>{{ error.title ? error.title : error.toString() }}</h3>
    <p v-if="error.description" v-html="error.description" />
    <vwc-button shape="rounded" layout="filled" connotation="cta" @click="$emit('confirm')">Sign Out</vwc-button>
  </div>
</template>

<script>
export default {
  name: 'Error',
  props: {
    error: {
      type: Object,
      default() {
        return {
          title: 'Oops, Something went wrong',
          description: ''
        };
      }
    }
  },
  emits: ['confirm']
};
</script>

<style scoped>
.logo {
  width: 87px;
  height: 74px;
}
</style>
