<template>
  <div class="flex-container-column">
    <p v-if="state.error">{{ state.error }}</p>
    <vwc-circular-progress :indeterminate="true" />
  </div>
</template>

<script>
import { onBeforeMount, reactive } from 'vue';
import { useAuth } from '@/modules/auth';

export default {
  name: 'VBCSSOCallback',

  setup(_, { emit }) {
    const state = reactive({
      error: ''
    });

    const { consumeSSOTokens } = useAuth();

    onBeforeMount(async () => {
      try {
        await consumeSSOTokens();
        emit('authSuccess');
      } catch (e) {
        state.error = e.toString();
        // TODO handle error - maybe redirect to login?
      }
    });

    return {
      state
    };
  },

  emits: ['authSuccess']
};
</script>

<style scoped>
.flex-container-column {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
