const VBC_AUTH_BASE_PATHNAME = '/login';
export const VBC_AUTH_STEPS = Object.freeze({
  BASE: `${VBC_AUTH_BASE_PATHNAME}`,
  CALLBACK: `${VBC_AUTH_BASE_PATHNAME}/callback`
});

const MS_TEAMS_BASE_PATHNAME = '/auth/ms-teams';

export const MS_TEAMS_AUTH_STEPS = Object.freeze({
  BASE: MS_TEAMS_BASE_PATHNAME,
  ADMIN_CONSENT_CALLBACK: `${MS_TEAMS_BASE_PATHNAME}/adminconsent`,
  USER_CONSENT: `${MS_TEAMS_BASE_PATHNAME}/consent`,
  START: `${MS_TEAMS_BASE_PATHNAME}/start`,
  CALLBACK: `${MS_TEAMS_BASE_PATHNAME}/callback`,
  LOGOUT: `${MS_TEAMS_BASE_PATHNAME}/logout`,
  POST_LOGOUT: `${MS_TEAMS_BASE_PATHNAME}/post-logout`
});
