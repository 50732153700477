import { removeCache as removeUserCache, useUser, useExtensions } from '@/modules/user';
import { removeCache as removeContextCache, useContext } from '@/modules/context';
import { removeCache as removeMyAppsCache } from '@/modules/my-apps';
import { removeCache as removeContactsCache, useContacts } from '@/modules/contacts';
import { useAuth } from '@/modules/auth';

import VBCBusService from '@/services/VBCBusService';
import Loggly from '@/services/loggly';

const { user } = useUser();
const { activeExtension } = useExtensions();
const { initContactsService } = useContacts();
const { context } = useContext();
const { setBeforeLogoutHandler } = useAuth();

async function destroyServices() {
  await Promise.all([removeUserCache(), removeContextCache(), removeMyAppsCache(), removeContactsCache(), VBCBusService.destroy()]);
}

async function initServices() {
  if (!user.value || !activeExtension.value) {
    throw new Error('Unable to initServices - user is undefined');
  }

  setBeforeLogoutHandler(async () => {
    await destroyServices();
  });

  return Promise.all([
    initContactsService(),
    VBCBusService.init({
      userId: user.value.id,
      accountId: user.value.accountId,
      username: user.value.username,
      extensionId: activeExtension.value.id,
      deviceId: context.value.deviceId
    }),
    Loggly.init({ accountId: user.value.accountId, userId: user.value.id })
  ]);
}

export function useServices() {
  return {
    initServices,
    destroyServices
  };
}
