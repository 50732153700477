import { VBC_AUTH_STEPS, MS_TEAMS_AUTH_STEPS } from './const';
import Home from '@/views/Home.vue';
import Login from '@/views/login/Login.vue';
import VBCSSOCallback from '@/views/login/VBCSSOCallback.vue';

export const HOME_ROUTE = {
  path: '/',
  name: 'Home',
  component: Home,
  meta: {
    requiresAuthentication: true
  }
};

export const VBC_LOGIN_ROUTE = {
  path: VBC_AUTH_STEPS.BASE,
  name: 'Login',
  component: Login,
  meta: {
    skipIfAuthenticated: true
  }
};

export const VBC_SSO_CALLBACK_ROUTE = {
  path: VBC_AUTH_STEPS.CALLBACK,
  name: 'VBCSSOCallback',
  component: VBCSSOCallback,
  meta: {
    skipIfAuthenticated: true
  }
};

// Microsoft Teams routes
export const MS_TEAMS_LOGIN_ROUTE = {
  path: MS_TEAMS_AUTH_STEPS.BASE,
  name: 'MSTeamsLogin',
  // route level code-splitting
  // this generates a separate chunk (login.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "ms-teams-auth-adapter" */ '../views/MSTeams/MSTeamsLogin.vue')
};

export const MS_TEAMS_SSO_ADMIN_CONSENT_CALLBACK_ROUTE = {
  path: MS_TEAMS_AUTH_STEPS.ADMIN_CONSENT_CALLBACK,
  name: 'MSTeamsAdminConsentCallback',
  component: () => import(/* webpackChunkName: "ms-teams-auth-adapter" */ '../views/MSTeams/MSTeamsAdminConsentCallback.vue')
};

export const MS_TEAMS_SSO_USER_CONSENT_ROUTE = {
  path: MS_TEAMS_AUTH_STEPS.USER_CONSENT,
  name: 'MSTeamsSSOUserConsent',
  component: () => import(/* webpackChunkName: "ms-teams-auth-adapter" */ '../views/MSTeams/MSTeamsSSOUserConsent.vue')
};

export const MS_TEAMS_SSO_START_ROUTE = {
  path: MS_TEAMS_AUTH_STEPS.START,
  name: 'MSTeamsSSOStart',
  component: () => import(/* webpackChunkName: "ms-teams-auth-adapter" */ '../views/MSTeams/MSTeamsSSOStart.vue')
};

export const MS_TEAMS_SSO_CALLBACK_ROUTE = {
  path: MS_TEAMS_AUTH_STEPS.CALLBACK,
  name: 'MSTeamsSSOCallback',
  component: () => import(/* webpackChunkName: "ms-teams-auth-adapter" */ '../views/MSTeams/MSTeamsSSOCallback.vue')
};

export const MS_TEAMS_SSO_LOGOUT_ROUTE = {
  path: MS_TEAMS_AUTH_STEPS.LOGOUT,
  name: 'MSTeamsSSOLogout',
  component: () => import(/* webpackChunkName: "ms-teams-auth-adapter" */ '../views/MSTeams/MSTeamsSSOLogout.vue')
};

export const MS_TEAMS_SSO_POST_LOGOUT_ROUTE = {
  path: MS_TEAMS_AUTH_STEPS.POST_LOGOUT,
  name: 'MSTeamsSSOPostLogout',
  component: () => import(/* webpackChunkName: "ms-teams-auth-adapter" */ '../views/MSTeams/MSTeamsSSOPostLogout.vue')
};
