import { VBC_API_GATEWAY } from '@/api/index';

export async function registerBusStation(context) {
  const response = await VBC_API_GATEWAY.post('/bus/bus/v2/register', {
    accountId: context.accountId,
    deviceId: context.deviceId,
    userId: context.userId,
    userName: context.username,
    extensionsIds: [context.extensionId],
    type: 'ws'
  });
  return response.data;
}

export function unregisterBusStation(context) {
  return VBC_API_GATEWAY.post('/bus/bus/v2/unregister', {
    accountId: context.accountId,
    deviceId: context.deviceId
  });
}
