import get from 'lodash.get';

export function toQueryString(queryParams: Record<string, string>) {
  const encodedQueryParams: Array<string> = [];
  for (const key in queryParams) {
    encodedQueryParams.push(`${key}=${encodeURIComponent(queryParams[key])}`);
  }
  return encodedQueryParams.join('&');
}

export function getQueryParameters() {
  const queryParams: Record<string, string> = {};
  const search = location.search.substr(1);

  if (!search) {
    return {};
  }

  search.split('&').forEach(item => {
    const split = item.split('=');
    queryParams[split[0]] = split[1] && decodeURIComponent(split[1]);
  });

  return queryParams;
}

export function getFragmentParameters() {
  const fragmentParams: Record<string, string> = {};
  const hash = location.hash.substr(1);

  if (!hash) {
    return {};
  }

  hash.split('&').forEach(item => {
    const split = item.split('=');
    fragmentParams[split[0]] = split[1] && decodeURIComponent(split[1]);
  });

  return fragmentParams;
}

export function calculateTimeDifferenceInHours(startTime: number, end?: number) {
  const endTime = end || Date.now();
  return Math.abs(endTime - startTime) / (1000 * 60 * 60); // difference in milliseconds
}

/* eslint-disable no-useless-escape */
const validUrlRegex = /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/i;

export function isValidURL(str: string) {
  if (!str) {
    return false;
  }
  return !!validUrlRegex.test(str);
}

export function sortAlphabeticalByDisplayName(indexA?: any, indexB?: any): number {
  const nameA = get(indexA, 'displayName', '').toUpperCase();
  const nameB = get(indexB, 'displayName', '').toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}
