import { createApp } from 'vue';
import VividContext from '@vonage/vvd-context';
import '@vonage/vwc-button';
import '@vonage/vwc-menu';
import '@vonage/vwc-list/vwc-list-item';
import '@vonage/vwc-circular-progress';
import { useFeatures } from '@/modules/features';

import App from './App.vue';
import router from './router';

// We are intentionally ignoring the promise returned from this function
VividContext.mount();

const app = createApp(App);

app.use(router).mount('#app');

const { initFeatures } = useFeatures();
initFeatures();
