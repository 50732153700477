import { reactive, computed } from 'vue';
import cloneDeep from 'lodash.clonedeep';

import { useMyApps } from '@/modules/my-apps';

const { appsConfig } = useMyApps();

export type VBCAction = {
  id: string;
  type: string;
  triggers?: Array<{ id: string }>;
  parameters?: object;
};

export enum TRIGGERS {
  AFTER_LOGIN = 'after-login'
}

export enum ACTIONS {
  SEND_EVENTS_TO_HOSTED_APP = 'send-event-to-hosted-app',
  GET_ACCESS_TOKEN = 'get-access-token',
  DOWNLOAD_FILE = 'download-file'
}

export enum EVENTS {
  CONTACTS_UPDATE = 'contacts-update'
}

const state = reactive<{ actionExecutorsByTypes: { [key: string]: Function }; featureToggles: { [key: string]: boolean } }>({
  actionExecutorsByTypes: {},
  featureToggles: {}
});

const actionsByTrigger = computed((): { [key: string]: VBCAction[] } => {
  const result = {};
  Object.values(appsConfig.value).forEach(appConfig => {
    if (appConfig.actions) {
      appConfig.actions.forEach(action => {
        const triggerIds = {};
        if (action.triggers) {
          action.triggers.forEach(trigger => (triggerIds[trigger.id] = true));
        }
        Object.keys(triggerIds).forEach(triggerId => {
          if (result[triggerId]) {
            result[triggerId].push(action);
          } else {
            result[triggerId] = [action];
          }
        });
      });
    }
  });
  return result;
});

function registerActionExecutor(actionTypeId: string, actionExecutor: Function): void {
  if (!actionTypeId) {
    throw new Error('Error registering action executer of invalid type. Got undefined');
  }
  if (typeof actionExecutor !== 'function') {
    throw new Error(`Error registering action executer of type ${actionTypeId}. A VBC action executer, must be a function`);
  }
  console.log(`Registering new executer for action of type ${actionTypeId}`);
  state.actionExecutorsByTypes[actionTypeId] = actionExecutor;
}

async function executeAction({ action, context }: { action: VBCAction; context: any }): Promise<any> {
  let triggerObject;
  if (!action) {
    throw new Error('Unable to execute action - got undefined');
  }
  const actionExecutor = state.actionExecutorsByTypes[action.type];
  if (!actionExecutor) {
    // TODO: once we figure out what to do with actions that the VBC-web-SDK exports and that are not defined in VBC-shell platform change this to throw exception
    console.error(`Unable to execute action - unsupported action of type ${action.type}`);
    // throw new Error(`Unable to execute action - unsupported action of type ${action.type}`);
    return Promise.resolve();
  }
  if (context && context.trigger) {
    triggerObject = action.triggers ? action.triggers.find(trigger => trigger.id === context.trigger) : undefined;
  }
  const clonedAction = cloneDeep(action);
  if (triggerObject && typeof triggerObject.parameters === 'object') {
    // Merge the trigger parameters with the action parameters before dispatching the action.
    clonedAction.parameters = { ...clonedAction.parameters, ...cloneDeep(triggerObject.parameters) };
  }
  return actionExecutor({ action: clonedAction, context });
}

async function executeActionByTrigger(triggerId: string, context?: object): Promise<any | void> {
  if (!triggerId) {
    console.error('Unable to execute actions - got undefined trigger');
  }
  if (actionsByTrigger.value[triggerId]) {
    try {
      return Promise.all(
        actionsByTrigger.value[triggerId].map(action =>
          executeAction({
            action: action,
            context: { ...context, trigger: triggerId }
          })
        )
      );
    } catch (e) {
      console.error(`Error while executing action from trigger ${triggerId} - ${e}`);
    }
  }
}

export function useActions() {
  return {
    registerActionExecutor,
    executeAction,
    executeActionByTrigger
  };
}
