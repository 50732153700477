// TODO This file is planned to move to an external npm package.
import { registerBusStation, unregisterBusStation } from '@/api/bus';

function getRandomInt(min, max) {
  return parseInt(Math.random() * max + min, 10);
}

function convertObjectToUint8Array(json) {
  const str = JSON.stringify(json, null, 0);
  const ret = new Uint8Array(str.length);
  for (let i = 0; i < str.length; i++) {
    ret[i] = str.charCodeAt(i);
  }
  return ret;
}

function createWSConnection(url) {
  const webSocket = new WebSocket(url);
  webSocket.binaryType = 'arraybuffer';
  return webSocket;
}

const constants = {
  MIN_RECONNECT_DELAY: 2000,
  MAX_SEED_RECONNECT_DELAY: 60000,
  MAX_RECONNECT_DELAY: 300000,
  NETWORK_ERROR_RECONNECTION_DELAY: 10000,
  RECONNECTION_DELAY_GROW_FACTOR: 1.3,
  ACTIVITY_TIMEOUT_SEC: 20 * 1000,
  PONG_TIMEOUT_SEC: 10 * 1000,
  WS_CLOSED_NORMAL: 1000
};

const PING_PACKET = convertObjectToUint8Array({ type: 'PING' });

class VBCBusService {
  constructor() {
    this.handlers = {};
    this.onReconnectHandlers = [];
    this.broadcastHandlers = [];
    this._retryCountBusDetails = 0;
    this._retryCountWS = 0;
    this._isWSConnected = false;
    this._wasOpened = false;
    this._activityTimeout = 0;
    this._pongTimeout = 0;
    this._initTimeout = 0;
    this._delaySeed = getRandomInt(constants.MIN_RECONNECT_DELAY, constants.MAX_SEED_RECONNECT_DELAY);
    this._networkConnectionEventHandlers = false;
    this._context = {};
  }

  async init(context) {
    try {
      // Clear any previous attempt to init the bus connection
      this._clearInitTimeout();

      if (context) {
        this._context = context;
      }
      this._retryCountBusDetails++;
      const busDetails = await registerBusStation(this._context);
      this.busConnectionUrl = busDetails.url;
      this._retryCountBusDetails = 0;
    } catch (error) {
      /* error can be unauthorized from api gw then we need to get new accessToken
            or general error (we couldn't get to the db/rabbit/frizzle).
            In both cases we will try to the the init command. */
      const delay = error.message === 'Network Error' ? constants.NETWORK_ERROR_RECONNECTION_DELAY : this._getDelay(this._retryCountBusDetails);
      const errorMessage = `[BUS STATION] - Failed to register bus station, ${error.message}`;
      console.info(`${errorMessage}. Reattempt will occur in ${delay.toFixed(2)} milliseconds.`);
      this._restartBusConnection(delay);
      return;
    }
    this._defineNetworkConnectionEventHandlers();
    await this._wsConnectAndListen();
  }

  stopNetworkActivity() {
    this._clearInitTimeout();
    this._clearActivityTimeout();
    this._clearPongTimeout();

    if (this._networkConnectionEventHandlers) {
      window.removeEventListener('online', this._handleNetworkConnectionEvent);
      window.removeEventListener('offline', this._handleNetworkConnectionEvent);
      this._networkConnectionEventHandlers = false;
    }

    this._cleanOldWebsocket();
    this._isWSConnected = false;
  }

  addHandler(serviceName, messageType, handler) {
    this.handlers[serviceName] = this.handlers[serviceName] || {};
    this.handlers[serviceName][messageType] = handler;
  }

  addBroadcastHandler(handler) {
    this.broadcastHandlers.push(handler);
  }

  ping() {
    if (!this.webSocket) {
      return;
    }
    this.webSocket.send(PING_PACKET);
    this._pongTimeout = setTimeout(() => this._wsConnectAndListen(), constants.PONG_TIMEOUT_SEC);
  }

  isConnected() {
    return this._isWSConnected;
  }

  onreconnect(handler) {
    this.onReconnectHandlers.push(handler);
  }

  async destroy() {
    this.stopNetworkActivity();
    try {
      await unregisterBusStation(this._context);
    } catch (e) {
      // We dont really care if this fails.
      console.error(e);
    }
  }

  _defineNetworkConnectionEventHandlers() {
    if (this._networkConnectionEventHandlers) {
      return;
    }
    this._networkConnectionEventHandlers = true;
    window.addEventListener('online', this._handleNetworkConnectionEvent.bind(this));
    window.addEventListener('offline', this._handleNetworkConnectionEvent.bind(this));
  }

  _handleNetworkConnectionEvent() {
    setTimeout(() => this._wsConnectAndListen(), constants.NETWORK_ERROR_RECONNECTION_DELAY);
  }

  _clearInitTimeout() {
    if (this._initTimeout) {
      clearTimeout(this._initTimeout);
      this._initTimeout = 0;
    }
  }

  _clearPongTimeout() {
    if (this._pongTimeout) {
      clearTimeout(this._pongTimeout);
      this._pongTimeout = 0;
    }
  }

  _clearActivityTimeout() {
    if (this._activityTimeout) {
      clearTimeout(this._activityTimeout);
      this._activityTimeout = 0;
    }
  }

  _restartActivityTimeout() {
    this._clearActivityTimeout();
    this._activityTimeout = setTimeout(() => this.ping(), constants.ACTIVITY_TIMEOUT_SEC);
  }

  _listen() {
    if (!this.webSocket) {
      const delay = this._getDelay(this._retryCountWS);
      console.debug(`[BUS STATION] - Socket is undefined. Creation of new one will be attempted in ${delay.toFixed(2)} milliseconds.`);
      this._restartBusConnection(delay);
      return;
    }

    this.webSocket.onmessage = messageEvent => {
      let payload;
      this._restartActivityTimeout();
      try {
        payload = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(messageEvent.data)));
      } catch (error) {
        const errorMessage = `[BUS STATION] - onmessage, ${error.message}`;
        console.error(`PushNotificationsService.init error: ${errorMessage}`);
      }

      if (!payload || !payload.type) {
        const errorMessage = '[BUS STATION] - Message in wrong format';
        console.error(`PushNotificationsService.init error: ${errorMessage}`);
        return;
      }

      if (payload.type === 'PONG') {
        this._clearPongTimeout();
        return;
      }

      if (!payload.service) {
        const errorMessage = '[BUS STATION] - Message in wrong format';
        console.error(`PushNotificationsService.init error: ${errorMessage}`);
        return;
      }

      const messageType = payload.type;
      const serviceName = payload.service;
      if (this.handlers[serviceName] && this.handlers[serviceName][messageType] && payload.data) {
        this.handlers[serviceName][messageType](payload.data);
      }

      this.broadcastHandlers.forEach(handler => handler(payload));
    };

    this.webSocket.onerror = event => {
      const errorMessage = `[BUS STATION] - Socket encountered error , type: ${event.type}`;
      console.info(`${errorMessage}, Closing socket.`);
      this.webSocket.close();
    };

    this.webSocket.onopen = () => {
      console.info('[BUS STATION] - Socket is open.');
      this._retryCountWS = 0;
      this._delaySeed = getRandomInt(constants.MIN_RECONNECT_DELAY, constants.MAX_SEED_RECONNECT_DELAY);
      this._isWSConnected = true;
      this._restartActivityTimeout();
      if (this._wasOpened) {
        this.onReconnectHandlers.forEach(handler => handler());
      }
      this._wasOpened = true;
    };

    this.webSocket.onclose = closeEvent => {
      if (closeEvent.code === constants.WS_CLOSED_NORMAL) {
        console.info('[BUS STATION] - Socket is closed NORMALLY');
        this._isWSConnected = false;
        this._retryCountWS = 0;
      } else {
        const delay = this._getDelay(this._retryCountWS);
        console.info(`[BUS STATION] - Socket is closed ,StatusCode: ${closeEvent.code}. Reconnect will be attempted in ${delay.toFixed(2)} milliseconds.`);
        this._restartBusConnection(delay);
      }
    };
  }

  async _wsConnectAndListen() {
    try {
      this._retryCountWS++;
      this._cleanOldWebsocket();
      this.webSocket = await createWSConnection(this.busConnectionUrl);
    } catch (error) {
      const delay = error.message === 'Network Error' ? constants.NETWORK_ERROR_RECONNECTION_DELAY : this._getDelay(this._retryCountWS);
      const errorMessage = `[BUS STATION] - Failed to create webSocket, ${error.message}`;
      console.info(`${errorMessage} Reattempt will occur in ${delay.toFixed(2)} milliseconds.`);
      this._restartBusConnection(delay);
      return;
    }
    this._listen();
  }

  _getDelay(retryCount) {
    /* eslint-disable-next-line */
    let delay = this._delaySeed * Math.pow(constants.RECONNECTION_DELAY_GROW_FACTOR, retryCount <= 0 ? 0 : retryCount - 1); // The initial retryCount seem to always be 1, but the initial power need to be 0
    if (delay > constants.MAX_RECONNECT_DELAY) {
      delay = constants.MAX_RECONNECT_DELAY;
    }
    return delay;
  }

  _restartBusConnection(delay) {
    if (this._initTimeout) {
      return;
    }
    this._clearActivityTimeout();
    this._clearPongTimeout();
    this._isWSConnected = false;
    this._initTimeout = setTimeout(() => this.init(), delay || constants.MIN_RECONNECT_DELAY);
  }

  _cleanOldWebsocket() {
    if (this.webSocket) {
      console.debug('[BUS STATION] - clearing old websocket');
      delete this.webSocket.onerror;
      delete this.webSocket.onclose;
      delete this.webSocket.onopen;
      this.webSocket.close();
    }
  }
}

const bus = new VBCBusService();

export default bus;
