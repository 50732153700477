import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useAuth } from '@/modules/auth';
import {
  HOME_ROUTE,
  VBC_LOGIN_ROUTE,
  VBC_SSO_CALLBACK_ROUTE,
  MS_TEAMS_LOGIN_ROUTE,
  MS_TEAMS_SSO_START_ROUTE,
  MS_TEAMS_SSO_CALLBACK_ROUTE,
  MS_TEAMS_SSO_USER_CONSENT_ROUTE,
  MS_TEAMS_SSO_LOGOUT_ROUTE,
  MS_TEAMS_SSO_POST_LOGOUT_ROUTE,
  MS_TEAMS_SSO_ADMIN_CONSENT_CALLBACK_ROUTE
} from '@/router/routes';

const { isUserAuthenticated } = useAuth();

const routes: Array<RouteRecordRaw> = [
  HOME_ROUTE,
  VBC_LOGIN_ROUTE,
  VBC_SSO_CALLBACK_ROUTE,
  MS_TEAMS_LOGIN_ROUTE,
  MS_TEAMS_SSO_START_ROUTE,
  MS_TEAMS_SSO_CALLBACK_ROUTE,
  MS_TEAMS_SSO_USER_CONSENT_ROUTE,
  MS_TEAMS_SSO_ADMIN_CONSENT_CALLBACK_ROUTE,
  MS_TEAMS_SSO_LOGOUT_ROUTE,
  MS_TEAMS_SSO_POST_LOGOUT_ROUTE
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (isUserAuthenticated.value) {
    if (to.meta.skipIfAuthenticated) {
      next({ name: HOME_ROUTE.name });
    } else {
      next();
    }
  } else if (!to.meta.requiresAuthentication) {
    next();
  } else {
    next({ name: VBC_LOGIN_ROUTE.name });
  }
});

export default router;
