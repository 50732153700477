
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useContext, VBCShellContext } from '@/modules/context';

export default defineComponent({
  setup() {
    const router = useRouter();
    const { setVBCShellContext } = useContext();

    function onAuthSuccess(context: VBCShellContext) {
      if (context) {
        console.log(`loaded vbc-shell-client with context ${JSON.stringify(context)}`);
        setVBCShellContext(context);
      }
      router.replace({ name: 'Home' });
    }

    return {
      onAuthSuccess
    };
  }
});
