import Dexie from 'dexie';
import set from 'lodash.set';

export default class BaseIndexedDB extends Dexie {
  constructor(name, version, config) {
    super(name);
    this.version(version).stores(config);
  }

  // for saving a store with keys that are not unique ids
  async saveStore(data, tableName) {
    this.transaction('rw', this.table(tableName), async () => {
      Object.keys(data).forEach(key => {
        this.table(tableName).put(data[key], key);
      });
    }).catch(error => {
      // Log or display the error
      console.error(`Error saving ${tableName} to indexDB: ${error}`);
    });
  }

  // for saving a table store with unique ids
  async saveTable(data, tableName) {
    this.transaction('rw', this.table(tableName), async () => {
      this.table(tableName).bulkPut(data);
    }).catch(error => {
      // Log or display the error
      console.error(`Error saving ${tableName} Data to indexDB: ${error}`);
    });
  }

  // returns an array of the requested table
  getAllTableData(tableName) {
    return this.table(tableName).toArray();
  }

  // return an object of all the of the data of the keys requested
  async getAllData(keysArray, tableName) {
    const objectStore = {};
    await this.transaction('r', this.table(tableName), async () => {
      keysArray.map(async key => {
        const value = await this.table(tableName).get(key);
        set(objectStore, key, value);
      });
    });
    return objectStore;
  }
}
