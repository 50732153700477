import { reactive } from 'vue';
import get from 'lodash.get';
import { useActions } from '@/modules/actions';

const { registerActionExecutor } = useActions();

const state = reactive<{ featureToggles: { [key: string]: boolean } }>({
  featureToggles: {}
});

function toggleFeature({ action }): void {
  const featureId = get(action, 'parameters.featureId');
  const flag = get(action, 'parameters.flag');
  if (!featureId) {
    throw new Error('Invalid parameters - No feature id provided');
  }
  state.featureToggles[featureId] = !!flag;
}

function getFeatureToggle({ action }): boolean {
  const featureId = get(action, 'parameters.featureId');
  return state.featureToggles[featureId] || false;
}

const FEATURES_ACTION_MAPPINGS = {
  'toggle-feature': toggleFeature,
  'get-feature-flag': getFeatureToggle
};

function initFeatures(): void {
  for (const [actionType, actionExecutor] of Object.entries(FEATURES_ACTION_MAPPINGS)) {
    try {
      registerActionExecutor(actionType, actionExecutor);
    } catch (e) {
      console.error(`Failed registering action of type: ${actionType} - ${e}`);
    }
  }
}

export function useFeatures() {
  return {
    initFeatures
  };
}
