import { MicroFrontendOrchestrator as MFO } from '@vonage/micro-frontends';
import { ACTIONS, useActions } from '@/modules/actions';
import { onMounted, reactive, toRaw, watch } from 'vue';
import { useExtensions, useUser } from '@/modules/user';
import { useAuth } from '@/modules/auth';
import { useFile } from '@/modules/file-utils.ts';
import { useMyApps } from '@/modules/my-apps';
import cloneDeep from 'lodash.clonedeep';
import cuid from 'cuid';
import VBCBusService from '@/services/VBCBusService';

const { appsConfig } = useMyApps();
const APP_CONTEXT_UPDATED_EVENT = 'app-context-updated';
const NEW_VBC_ACCESS_TOKEN = 'new-vbc-access-token';
const MAIN_VIEW_PLUGIN = 'main-view';
const BUS_EVENT_TRIGGER_SUFFIX = '-bus-event';

export function sendEventToHostedApp(appId: string, event: string, payload?) {
  const permittedEvents = appsConfig.value?.[appId]?.permissions?.events || [];
  if (!permittedEvents.includes(event)) {
    console.log(`Event ${event} is not permitted for app ${appId}`);
  }
  const appInstancesIds = MFO.getInstancesIds(appId);
  if (!appInstancesIds) {
    return;
  }
  appInstancesIds.forEach(appInstanceId => {
    MFO.send(appInstanceId, '', event, payload);
  });
}

export function useAppsRenderer(renderedApp, context, vueContext) {
  const APP_STATES = Object.freeze({
    LOADING: 'loading',
    READY: 'ready',
    ERROR: 'error',
    TERMINATED: 'terminated'
  });

  const { user } = useUser();
  const { activeExtension, extensions } = useExtensions();
  const { accessToken, refreshToken } = useAuth();
  const { download } = useFile();
  const { executeAction, registerActionExecutor } = useActions();
  const state = reactive({
    appState: APP_STATES.LOADING,
    app: cloneDeep(renderedApp),
    appInstanceId: '',
    error: ''
  });
  watch(context, () => sendEventToHostedApp(state.app.appId, APP_CONTEXT_UPDATED_EVENT, context));
  watch(accessToken, () => sendEventToHostedApp(state.app.appId, NEW_VBC_ACCESS_TOKEN, { accessToken: accessToken.value, refreshToken: refreshToken.value }));
  registerActionExecutor('send-event-to-hosted-app', ({ action, context }) =>
    sendEventToHostedApp(state.app.appId, action?.parameters?.eventName || context.trigger, context.event)
  );
  registerActionExecutor(ACTIONS.DOWNLOAD_FILE, ({ action, context }) => {
    download(action.parameters);
  });

  VBCBusService.addBroadcastHandler(event => {
    const eventName = event.service.toLowerCase() + BUS_EVENT_TRIGGER_SUFFIX;
    sendEventToHostedApp(state.app.appId, eventName, event);
  });

  const rootElementId = cuid();

  function ackRequestWithPayload(appInstanceId, eventId, payload?) {
    MFO.send(appInstanceId, eventId, '', payload);
  }

  function ackRequestWithError(appInstanceId, eventId, errorMessage) {
    MFO.send(appInstanceId, eventId, '', undefined, errorMessage);
    console.warn(`AppsRenderer - ackRequestWithError: ${errorMessage}. Application id: ${state.app.appId}, dom id: ${appInstanceId}`);
  }

  async function onVBCAction(appInstanceId, eventId, eventName, action) {
    try {
      const rawAppObject = toRaw(state.app);
      const permittedActions = rawAppObject?.permissions?.actions || [];
      if (!permittedActions.includes(action.type)) {
        ackRequestWithError(appInstanceId, eventId, `${rawAppObject.appId} Missing permissions to run action of type ${action.type}`);
        return;
      }
      action.appId = state.app.appId;
      const result = await executeAction({ action, context: { appInstanceId, eventId, eventName } });
      return ackRequestWithPayload(appInstanceId, eventId, result);
    } catch (e) {
      console.error(`Action type ${action.type} requested by ${state.app.appId}, failed with: ${e.toString()}`);
      ackRequestWithError(appInstanceId, eventId, e.toString());
    }
  }

  function onBeforeAppInjected(appInstanceId) {
    state.appInstanceId = appInstanceId;
    // TODO fix typing issues in the SDK
    return {};
  }

  function onAppInitialized(appInstanceId, eventId) {
    if (!state.app) {
      ackRequestWithError(appInstanceId, eventId, `Application not recognized by VBC`);
      return;
    }

    const rawAppObject = toRaw(state.app);

    const extensionToken = rawAppObject.extensionToken;
    if (!extensionToken) {
      ackRequestWithError(appInstanceId, eventId, `${rawAppObject.appId} Missing permissions - extensionToken does not exist for this user`);
      return;
    }

    let vbcAccessToken;
    let vbcRefreshToken;
    let vbcExtension;
    let vbcExtensionLocale;
    const vbcContext = { ...toRaw(context.value), appParameters: rawAppObject?.plugins?.[MAIN_VIEW_PLUGIN]?.uiSettings?.parameters || {} };
    const vbcAccountInfo = {
      username: '',
      vbcAccountId: '',
      vbcUserId: '',
      vbcAccountExtensions: {},
      virtualReceptionists: [],
      disabledVrNumbers: [],
      allowCallBlocking: false
    };
    if (user && user.value) {
      vbcExtensionLocale = user.value.locale;
      vbcAccountInfo.vbcAccountId = user.value.accountId;
      vbcAccountInfo.vbcUserId = user.value.id;
    }
    const vbcAppData = {
      version: process.env.VUE_APP_VERSION,
      platform: vbcContext.hostClientType,
      hostPlatform: vbcContext.platform,
      release: process.env.VUE_APP_ENV
    };

    if (rawAppObject.isPrivileged) {
      // We want to send the VBC access token and refresh token to the app in case it has priviliges to interact with the VBC back-end.
      vbcAccessToken = accessToken.value;
      vbcRefreshToken = refreshToken.value;
      if (activeExtension.value) {
        vbcExtension = { ...toRaw(activeExtension.value), externalId: activeExtension.value.id };
        vbcAccountInfo.vbcAccountExtensions = toRaw(extensions.value).reduce((allExtensions, extension) => {
          allExtensions[extension.extension] = extension;
          return allExtensions;
        }, {});
        if (user.value) {
          vbcAccountInfo.username = user.value.username;
        }
        vbcAccountInfo.virtualReceptionists = [];
        vbcAccountInfo.disabledVrNumbers = [];
        vbcAccountInfo.allowCallBlocking = false;
      }
    }

    const vbcEvents = rawAppObject?.permissions?.events || [];
    ackRequestWithPayload(appInstanceId, eventId, {
      extensionToken,
      vbcAccessToken,
      vbcRefreshToken,
      vbcContext,
      vbcExtension,
      vbcAccountInfo,
      vbcExtensionLocale,
      vbcEvents,
      vbcAppData
    });
    console.log(`AppsRenderer - onAppInitialized: Application id: ${rawAppObject.appId}, dom id: ${appInstanceId}`);
  }

  function onAppReady(appInstanceId, eventId, eventName, isReady = true) {
    if (isReady) {
      state.appState = APP_STATES.READY;
    } else {
      state.appState = APP_STATES.ERROR;
    }
    ackRequestWithPayload(appInstanceId, eventId);
    console.log(`AppsRenderer - onAppReadyResult: Application id: ${state.app.appId}, dom id: ${appInstanceId}, isReady: ${isReady}`);
  }

  function onAppError(appInstanceId, eventId) {
    state.appState = APP_STATES.ERROR;
    ackRequestWithPayload(appInstanceId, eventId);
    console.log(`AppsRenderer - onAppError: Application id: ${state.app.appId}, dom id: ${appInstanceId}`);
  }

  function onAppTerminate(appInstanceId, eventId) {
    ackRequestWithPayload(appInstanceId, eventId);
    MFO.remove(appInstanceId);
    state.appState = APP_STATES.TERMINATED;
    console.log(`AppsRenderer - onAppTerminate: Application id: ${state.app.appId}, dom id: ${appInstanceId}`);
  }

  function onModalOpen(appInstanceId, eventId) {
    vueContext.emit('modal-toggle', true);
    ackRequestWithPayload(appInstanceId, eventId);
  }

  function onModalClose(appInstanceId, eventId) {
    vueContext.emit('modal-toggle', false);
    ackRequestWithPayload(appInstanceId, eventId);
  }

  onMounted(async () => {
    // The rootElement must exist in the DOM, so we have to wait for the component to mount before calling MFO.inject
    const options = {
      type: state.app?.plugins?.[MAIN_VIEW_PLUGIN]?.uiSettings?.type || 'iframe',
      url: state.app?.plugins?.[MAIN_VIEW_PLUGIN]?.uiSettings?.url || '',
      customEvents: { VBCAction: onVBCAction, ModalOpen: onModalOpen, ModalClose: onModalClose },
      onBeforeInjected: onBeforeAppInjected,
      onInitialized: onAppInitialized,
      onReady: onAppReady,
      onError: onAppError,
      onTerminate: onAppTerminate
    };

    console.log(`Injecting app:${state.app.appId} with type: ${options.type} and url:${options.url}`);
    try {
      await MFO.inject(rootElementId, state.app.appId, options);
    } catch (e) {
      state.appState = APP_STATES.ERROR;
      state.error = e.toString();
    }
  });
  return {
    state,
    APP_STATES,
    rootElementId
  };
}
