import isEmpty from 'lodash.isempty';
import { reactive, computed, toRef, toRaw } from 'vue';

import VBCUserService, { VBCUser, VBCExtension } from '@/services/VBCUserService';
import { useActions } from '@/modules/actions';

const state = reactive<{ user: VBCUser | null; extensions: Array<VBCExtension> }>({
  user: null,
  extensions: []
});

const { registerActionExecutor } = useActions();

let userService;

export async function removeCache() {
  if (userService) {
    await userService.destroy();
  }
}

async function getUserInfo() {
  return toRaw(state.user);
}

async function initUserInfoExecutor() {
  const actionType = 'get-user-info';
  const getUserInfoExecutor = async () => {
    return await getUserInfo();
  };

  try {
    registerActionExecutor(actionType, getUserInfoExecutor);
  } catch (e) {
    console.error(`Failed registering action of type: ${actionType} - ${e}`);
  }
}

async function loadUserAndConfigureUserInfoExecutor(): Promise<void> {
  userService = new VBCUserService();
  let user, extensions;
  try {
    ({ user, extensions } = await userService.getUser());
  } catch (e) {
    console.error(e);
    throw e;
  }

  const defaultExtension = extensions.find(extension => extension.isDefault);
  const nonVirtualExtension = extensions.find(extension => !extension.isVirtual);

  if (isEmpty(extensions) || (!defaultExtension && !nonVirtualExtension)) {
    throw new Error('No Extensions');
  }

  state.user = user;
  state.extensions = extensions;

  await initUserInfoExecutor();
}

const defaultExtension = computed(() => state.extensions.find(extension => extension.isDefault));
const activeExtension = computed(() => defaultExtension.value || state.extensions.find(extension => !extension.isVirtual));

export function useUser() {
  return {
    user: toRef(state, 'user'),
    loadUser: loadUserAndConfigureUserInfoExecutor
  };
}

export function useExtensions() {
  return {
    extensions: toRef(state, 'extensions'),
    activeExtension
  };
}
