import axios from 'axios';

async function download(fileToDownload) {
  axios({
    url: fileToDownload.url,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': ' GET, PUT, POST, DELETE, OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
      'Access-Control-Allow-Credentials': 'false'
    }
  }).then(response => {
    const suggestedFilename = fileToDownload.defaultFilename || 'downloaded_file';
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const downloadLink = document.createElement('a');
    const downloadUrl = URL.createObjectURL(blob);

    downloadLink.href = downloadUrl;
    downloadLink.download = suggestedFilename;

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(downloadUrl);
  });
}

export function useFile() {
  return {
    download
  };
}
