<template>
  <div id="avatar" class="avatar-container" :class="size" @click="$emit('click')">
    <!--    <vwc-icon type="user-full" class="avatar-icon" size="small" style="color: white;" />-->
    <p style="color:white" class="initials" :class="`initials-${size}`">{{ initials }}</p>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  emits: ['click'],
  props: {
    size: {
      type: String,
      default: 'sm',
      validator(size) {
        return ['sm', 'lg'].indexOf(size.toLowerCase()) >= 0;
      }
    },
    firstName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    let initials;
    if (!props.firstName && !props.lastName) {
      initials = '';
    } else {
      initials = `${props.firstName.charAt(0)}${props.lastName.charAt(0)}`;
    }
    return {
      initials
    };
  }
};
</script>

<style scoped>
.avatar-container {
  background: #871fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sm {
  width: 48px;
  height: 48px;
}

.lg {
  width: 64px;
  height: 64px;
}

.initials-lg {
  font-weight: 700;
  font-size: 16px;
}

.initials {
  text-transform: uppercase;
}
</style>
