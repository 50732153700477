import { reactive, toRefs, computed } from 'vue';

import { VBC_AUTH_STEPS } from '@/router/const';
import { useActions, ACTIONS } from '@/modules/actions';

import VBCAuthService from '@/services/VBCAuthService';

const { registerActionExecutor } = useActions();

// Similar to Vuex state
const state = reactive({
  accessToken: null,
  refreshToken: null
});

// eslint-disable-next-line @typescript-eslint/no-empty-function
let onBeforeLogout: () => Promise<void> = () => Promise.resolve();

// Services hide all the ugly API logic for us and can also be extracted to a separate pure JS/TS package easily.
const authService = new VBCAuthService({
  clientId: process.env.VUE_APP_SSO_CLIENT_ID,
  authProviderURL: process.env.VUE_APP_SSO_URL,
  authCallbackURL: `${window.location.origin}${VBC_AUTH_STEPS.CALLBACK}`,
  postLogoutURL: window.location.origin,
  // The service should take care for the token by itself.
  // This callback is called whenever the tokens are updated.
  onTokensUpdated: tokens => {
    state.accessToken = tokens.apiGatewayAccessToken;
    state.refreshToken = tokens.apiGatewayRefreshToken;
  }
});

// Similar to Vuex getter
const isUserAuthenticated = computed(() => !!state.accessToken);

// Similar to Vuex actions - can be either sync or async
// Might have more logics outside of the service for more complex actions.
// We should either wrap the service with our own functions so the service will not loose the correct "this" context, or map directly to the service function and use bind(this)
function redirectToSSO(options) {
  authService.redirectToSSO(options);
}

function getAccessToken() {
  return authService.getAccessToken();
}

async function refreshAccessToken() {
  await authService.refreshAccessToken;
}

async function consumeSSOTokens(options) {
  return await authService.consumeSSOTokens(options);
}

function setTokens(tokens) {
  authService.setTokens(tokens);
}

function setBeforeLogoutHandler(func: () => Promise<void>): void {
  onBeforeLogout = func;
}

async function logout(options = {}): Promise<void> {
  try {
    await onBeforeLogout();
  } catch (e) {
    // TODO what are we supposed to do in this case? Probably a bug
    console.error(e);
  }
  authService.logout(options);
}

registerActionExecutor(ACTIONS.GET_ACCESS_TOKEN, getAccessToken);

// Composition function exposes the set of reactive values and functions that the app needs.
export function useAuth() {
  return {
    ...toRefs(state),
    isUserAuthenticated,
    redirectToSSO,
    consumeSSOTokens,
    getAccessToken,
    refreshAccessToken,
    setTokens,
    setBeforeLogoutHandler,
    logout
  };
}
