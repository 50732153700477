export enum PLATFORM {
  MS_TEAMS = 'ms-teams',
  WEB = 'vbc_shell_client_web'
}

export enum LOCAL_STORAGE {
  SSO_STATE_KEY = 'sso.state',
  VBC_TOKENS_CACHE_KEY = 'sso.VBCTokens',
  TEAMS_STATE_ID_KEY = 'MSTeams.state'
}
