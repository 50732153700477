
import get from 'lodash.get';
import { defineComponent, computed, reactive, onBeforeMount } from 'vue';

// Modules
import { useContext } from '@/modules/context';
import { useMyApps } from '@/modules/my-apps';
import { useUser } from '@/modules/user';
import { useHost, resolveHost } from '@/modules/host';
import { useActions, TRIGGERS } from '@/modules/actions';
import { useServices } from '@/modules/service-manager';

// Components
import TitleBar from '@/components/navigation/TitleBar.vue';
import AppsRenderer from '@/components/AppsRenderer.vue';
import SpinningLogo from '@/components/SpinningLogo.vue';
import Error from '@/components/Error.vue';

import { AppLicenseError } from '@/common/errors';

export default defineComponent({
  name: 'Home',

  setup() {
    const state = reactive({
      loading: true,
      error: undefined,
      backgroundOverlay: false
    });

    const { context } = useContext();
    const { loadUser } = useUser();
    const { appsConfig, loadAppsConfig } = useMyApps();
    const { executeActionByTrigger } = useActions();
    const { init, logout } = useHost();
    const { initServices } = useServices();

    const renderedApp = computed(() => appsConfig.value[context.value.appId]);
    const titleBarPlugin = computed(() => get(renderedApp.value, 'plugins.titlebar', {}));

    function toggleBackgroundOverlay(toggle: boolean) {
      state.backgroundOverlay = toggle;
    }

    onBeforeMount(async () => {
      try {
        await resolveHost();
        await loadUser();
        await loadAppsConfig();
        if (!renderedApp.value) {
          throw new AppLicenseError();
        }
        await init();
        await executeActionByTrigger(TRIGGERS.AFTER_LOGIN);
        await initServices();
      } catch (e) {
        state.error = e;
      } finally {
        state.loading = false;
      }
    });

    return {
      state,
      renderedApp,
      context,
      titleBarPlugin,
      logout,
      toggleBackgroundOverlay
    };
  },

  components: {
    Error,
    TitleBar,
    AppsRenderer,
    SpinningLogo
  }
});
