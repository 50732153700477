import { VBC_API_GATEWAY } from '@/api/index';

// export async function getLocaleDetails(version) {
//   const response = await VBC_API_GATEWAY.get(`/vbc-api/locale/dynamic-file?v=${version}`);
//   return response.data;
// }

export async function readNewSettings() {
  const response = await VBC_API_GATEWAY.get('pilot/2/adminv2/api/ucaas/user/newSettings');
  return response.data;
}
