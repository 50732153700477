import { VBC_API_GATEWAY, VBC_LAMBDA_API } from '@/api/index';
import { PLATFORM } from '@/common/const';

type SSOTokensResponse = { apiGatewayAccessToken: string; apiGatewayRefreshToken: string; idpAccessToken: string; idpLogoutToken: string };
export async function getSSOTokens(code: string, redirectUri: string): Promise<SSOTokensResponse> {
  const response = await VBC_LAMBDA_API.post('/idp/token', {
    code,
    redirect: redirectUri,
    platform: PLATFORM.WEB
  });
  return response.data;
}

type RefreshTokenResponse = { access_token: string; refresh_token: string };
export async function refreshAccessTokenAPI(refreshToken): Promise<RefreshTokenResponse> {
  const response = await VBC_API_GATEWAY.post(`/oauth/token?grant_type=refresh_token&refresh_token=${refreshToken}`, {
    refresh_token: refreshToken, // eslint-disable-line @typescript-eslint/camelcase
    grant_type: 'refresh_token' // eslint-disable-line @typescript-eslint/camelcase
  });
  return response.data;
}
